.App {
  text-align: center;
}

.App-cover {
  width: "100%";
}

.App-logo {
  object-fit: contain;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
